import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ClientGuard from 'utils/route-guard/ClientGuard';
import { Message } from '@mui/icons-material';
import QuickBookConnect from '../views/quickbook';
import { MULTY_ACCOUNT_ENABLED_FLAG,CLIENT_ACCOUNT_ENABLED_FLAG } from '../config';
// page routing
const ClientDashboard = Loadable(lazy(() => import('views/dashboard/ClientDashboard')));
const MessagePage = Loadable(lazy(() => import('views/messages')));
const PayrollPage = Loadable(lazy(() => import('views/payroll')));
const TeamPage = Loadable(lazy(() => import('views/team')));
const AccountingPage = Loadable(lazy(() => import('views/accounting')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const PlayArea = Loadable(lazy(() => import('views/sample-page2')));
const NewClientReports = Loadable(lazy(() => import('views/reporting/NewClientReports')));
const ReportsDetailPage = Loadable(lazy(() => import('views/reporting/ReportDetail')));
const NotFoundPage = Loadable(lazy(() => import('views/common/404')));
const FilterReports = Loadable(lazy(()=>import('views/reporting/amReports/FilterReports')))

const ReportComparePage = Loadable(lazy(() => import('views/reporting/ReportCompare')));


// const Completion = Loadable(lazy(() => import('views/stripe-checkout/Complete')));
// const PaymentPage = Loadable(lazy(() => import('views/group/payment/Payment')));


const AccountsPage = Loadable(lazy(() => import('views/accounts/Accounts')))
const GroupDetails = Loadable(lazy(() => import('views/group/GroupDetails')));

const multyFlag = MULTY_ACCOUNT_ENABLED_FLAG === 'true';

const MainRoutes = {
    path: '/client',
    element: (
        <ClientGuard>
            <MainLayout />
        </ClientGuard>
    ),
    children: [
        {
            path: '/client',
            element: <ClientDashboard />
        },
        {
            path: '/client/accounting',
            element: <AccountingPage />
        },
        {
            path: '/client/groupdetails',
            element: <GroupDetails amView={false} />,
            children: [{ path: ':id', element: <GroupDetails amView={false}/> }]
        },
        {
            path: '/client/messages',
            element: <MessagePage props={{ pageName: 'Messages Page' }} />
        },
        {
            path: '/client/play',
            element: <PlayArea />
        },
        {
            path: '/client/settings',
            element: <UserSettings />
        },
        // {
        //     path: '/client/reports',
        //     element: <ClientReports />,
        //     children: [{ path: ':id', element: <ClientReports /> }]
        // },
         {
            path: '/client/reports',
            element: <NewClientReports />,
            children: [{ path: ':id', element: <NewClientReports /> }]
        },
        {
            path: '/client/report-filter',
            element: <FilterReports />,
            children: [{ path: ':id', element: <FilterReports /> }]
        },
        {
            path: '/client/report',
            element: <ReportsDetailPage />,
            children: [{ path: ':id', element: <ReportsDetailPage /> }]
        },
        {
            path: '/client/report/:id/compare',
            element: <ReportComparePage props={{ pageName: 'Report Compare Page' }} />
        },
        {
            path: '/client/payroll',
            element: <PayrollPage props={{ pageName: 'Payroll Page' }} />
        },
        {
            path: '/client/team',
            element: <TeamPage props={{ pageName: 'Team Page' }} />
        },
        {
            path: '/client/qbo/callback',
            element: <QuickBookConnect props={{ pageName: 'QuickBook Connect' }} />
        },
        multyFlag ? {
            path: '/client/accounts',
            element: <AccountsPage />
        } : "",
        // {
        //     path: '/client/groupdetails/:id/payment',
        //     element: <PaymentPage props={{ pageName: 'Payment Checkout Page' }}/>,
        // },
        // {
        //     path: '/client/complete',
        //     element: <Completion props={{ pageName: 'Checkout Completion Page' }} />
        // },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default MainRoutes;
