
import ResponseStatus from 'models/common/ResponseStatus.tsx';

const getResponseStatus = (response) => {
    const responseStatus = new ResponseStatus();
    if (response.status) {
        responseStatus.status = response.status;
    }
    if (response.message) {
        responseStatus.message = response.message;
    }
    if (response.statusText) {
        responseStatus.statusText = response.statusText;
    }
    if (response.data) {
        responseStatus.data = response.data;
    }
    return responseStatus;
}

export default getResponseStatus;