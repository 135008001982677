import { apiGet, awsApiUpdate, apiDjangoGet, apiDjangoGetWithReponse, apiPostDjango, apiUpdate, apiDelete, apiPostDjangoWithResponse  } from './apiClient';
import getResponseStatus from './util'

// Message Requests
const getMessageTemplateListAPI = async ( ) => apiDjangoGet({ url: `/messaging/message-template/` });

const getMessageTemplate4ChannelListAPI = async (params = null ) => {
    try {
        const response = await apiDjangoGetWithReponse({ url: '/messaging/message-template/', params  });
        if ( response.status !== 201  && response.status !== 200) {
            return getResponseStatus(response);
        }
        return response;
    } catch (error) {
        return getResponseStatus(error);
    }
};

const getMessageTemplateAPI = async ({ id }) => apiDjangoGet({ url: `/messaging/message-template?id=${id}/` });

const createMessageTemplateAPI = async ({ data }) => apiPostDjango({ url: `/messaging/message-template/`, data });

const updateMessageTemplateAPI = async ({ id, data }) => apiUpdate({ url: `/messaging/message-template/${id}/`, data });

const deleteMessageTemplateAPI = async ({ id }) => apiDelete({ url: `/messaging/message-template/${id}/` });

const publishMessageTemplateApi = async (id) => {
    try {
        const response = await apiPostDjangoWithResponse({ url: `/messaging/message-template/${id}/publish`  });
        if ( response.status !== 201  && response.status !== 200) {
            return getResponseStatus(response);
        }
        return response;
    } catch (error) {
        return getResponseStatus(error);
    }
};

const unpublishMessageTemplateApi = async (id) => {
    try {
        const response = await apiPostDjangoWithResponse({ url: `/messaging/message-template/${id}/unpublish`  });
        if ( response.status !== 201  && response.status !== 200) {
            return getResponseStatus(response);
        }
        return response;
    } catch (error) {
        return getResponseStatus(error);
    }
};


// Message Requests
const getMessageListAPI = async () => apiGet({ url: `/message/list/` });

const getMessageAPI = async ({ messageID }) => apiGet({ url: `/message?id=${messageID}` });

const updateMessageAPI = async ({ id, data }) => awsApiUpdate({ url: `/message?id=${id}`, data });

const createMessageAPI = async ({ data }) => undefined;
// const url = `${DOMAIN}message`;
// const response = await apiPost({ method: 'POST', url, body });
//     return undefined;
// };

const deleteMessageAPI = async ({ id }) => undefined;
// const url = `${DOMAIN}message?id=${id}`;
// const response = await apiFetch({ method: 'DELETE', url });
//     return undefined;
// };

export { 
    getMessageTemplateListAPI, 
    getMessageTemplate4ChannelListAPI,
    getMessageTemplateAPI, 
    updateMessageTemplateAPI, 
    createMessageTemplateAPI, 
    deleteMessageTemplateAPI ,
    publishMessageTemplateApi,
    unpublishMessageTemplateApi,
    getMessageListAPI, 
    getMessageAPI, 
    updateMessageAPI, 
    createMessageAPI, 
    deleteMessageAPI 
};
